import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {withNamespaces} from "react-i18next";
import axios from "axios";
import Constants from "../../Constants";

const LatestListing = ({t}, props) => {
    const location = useLocation()
    const search = useLocation().search;
    const search_string = new URLSearchParams(search).get("search") != null ? new URLSearchParams(search).get("search"): '';
    const category_id = new URLSearchParams(search).get("category_id") != null ? new URLSearchParams(search).get("category_id"): '';

    const [listings, setListings] = useState([])
    const getListing = () => {
        axios.get(Constants.BASE_URL + `/listing?search=${search_string}&category_id=${category_id}`).then(res => {
            setListings(res.data.data)
        })
    }

    useEffect(() => {
        getListing()
    }, []);


    return (
        <div className="container feature-area section-gap">
            <div className="row">
                {location.pathname=='/listing' ?
                    <div className="col-md-12">
                        <p>{t('LISTING FEED')}</p>
                        <h4>{t('Find latest listing')}</h4>
                    </div>:null
                }

                <div className="row mt-5">
                    {listings.map((listing, index)=>(
                        <div key={index} className="col-md-4 mt-4">
                            <Link to={'/listing/'+listing.slug} key={index}>
                                <div className="feature-item">
                                    <img src={listing.feature_photo} alt={'Feature'} className={'feature-image'}/>
                                    <div className="overly"/>
                                    <div className="content">
                                        <div className="content-data">
                                            <div className="content-image">
                                                <img src={listing.feature_photo} alt={'Feature'} className={'feature-image'}/>
                                            </div>
                                            <h4>{listing.title}</h4>
                                            <h6>{listing.sub_title}</h6>
                                            <a href={listing.phone}><i className="fa-solid fa-phone-volume"></i> {listing.phone}</a>
                                            <address><i className="fa-solid fa-location-pin"></i> {listing.address}</address>
                                        </div>
                                    </div>
                                    <div className="content-footer">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <button><i className="fa-brands fa-servicestack"></i></button>
                                                <span> {listing.category}</span>
                                                <button data-tip="hello world" className={'ms-3'}>1+</button>

                                            </div>
                                            <div>
                                                <button data-tip="hello world"><i className="fa-solid fa-magnifying-glass"></i></button>
                                                <button><i className="fa-solid fa-heart"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="badge-area">
                                        <button><i className="fa-solid fa-bolt"></i></button>
                                        <button>{t('Open')}</button>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}

                    <div className="col-md-12 mt-5 text-center">
                        <Link to={'/listing'}><button className={'explore-button'}>{t('Explore More Places')}</button></Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withNamespaces() (LatestListing);
