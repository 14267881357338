import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './../node_modules/bootstrap/dist/js/bootstrap.bundle.min'
import './App.css';
import './Assets/Styles/style.scss'
import {BrowserRouter, Route} from "react-router-dom";
import NavBar from "./Components/Includes/NavBar";
import ScrollToTop from "react-scroll-to-top";
import Footer from "./Components/Includes/Footer";
import Home from "./Components/Pages/Home";
import Login from "./Components/Pages/Auth/Login";
import Listing from "./Components/Pages/Listing";
import ListingDetails from "./Components/Pages/ListingDetails";
import Explore from "./Components/Pages/Explore";
import Register from "./Components/Pages/Auth/Register";
import {useEffect} from "react";
import {withNamespaces} from 'react-i18next';
import axios from "axios";
import Constants from "./Constants";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy";
import Auth from "./Auth";
import SelectCategory from "./Components/Pages/SelectCategory";
import AddListing from "./Components/Pages/AddListing";
import Featured from "./Components/Pages/Featured";
import Testimonial from "./Components/Pages/TestimonialPage";
import Blog from "./Components/Pages/Blog";
import MyContact from "./Components/Pages/dashboard/MyContact";
import AboutUs from "./Components/Pages/AboutUs";
import ContactUs from "./Components/Pages/ContactUs";
import Dashboard from "./Components/Pages/dashboard/Dashboard";
import MyListing from "./Components/Pages/dashboard/MyListing";
import SelectListingType from "./Components/Pages/SelectListingType";
import Services from "./Components/Pages/Services";
import GotoTop from "./Components/Includes/GotoTop";
import PayNow from "./Components/Pages/dashboard/PayNow";
import PricePlan from "./Components/Pages/PricePlan";
import EditListing from "./Components/Pages/dashboard/EditListing";
import ForgetPassword from "./Components/Pages/Auth/ForgetPassword";
import ResetPassword from "./Components/Pages/Auth/ResetPassword";
import ChangePassword from "./Components/Pages/Auth/ChangePassword";

function App({t}) {

    useEffect(() => {
        if (localStorage.user_token !== undefined) {
            let token = localStorage.user_token
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
        if (localStorage.user_token != undefined) {
            checkAuth()
        }

        axios.interceptors.request.use(function (config) {
            config.headers.lang = localStorage.language ?? 'en';
            return config;
        });


    }, [])


    const checkAuth = () => {
        axios.get(Constants.BASE_URL + '/auth-check').then(res => {
            if (res.data.status != true) {
                localStorage.removeItem('user_email')
                localStorage.removeItem('user_name')
                localStorage.removeItem('user_token')
                window.location.reload()
            }
        }).catch(errors => {
            localStorage.removeItem('user_email')
            localStorage.removeItem('user_name')
            localStorage.removeItem('user_token')
           // window.location.reload()
        })
    }


    return (
        <>
            <BrowserRouter>
                <GotoTop/>
                <NavBar/>
                <Route exact path={'/'} component={Home}/>
                <Route exact path={'/listing'} component={Listing}/>
                <Route exact path={'/featured'} component={Featured}/>
                <Route exact path={'/testimonial'} component={Testimonial}/>
                <Route exact path={'/blog'} component={Blog}/>
                <Route exact path={'/about-us'} component={AboutUs}/>
                <Route exact path={'/contact-us'} component={ContactUs}/>
                <Route exact path={'/explore'} component={Explore}/>
                <Route exact path={'/listing/:slug'} component={ListingDetails}/>
                <Route exact path={'/privacy-policy'} component={PrivacyPolicy}/>
                <Route exact path={'/services'} component={Services}/>
                <Route exact path={'/login'} component={Login}/>
                <Route exact path={'/register'} component={Register}/>
                <Route exact path={'/forgot-password'} component={ForgetPassword}/>
                <Route exact path={'/reset-password'} component={ResetPassword}/>
                <Route exact path={'/price-plan'} component={PricePlan}/>
                <Auth exact path={'/select-type'} component={SelectListingType}/>
                <Auth exact path={'/select-category/:type_id'} component={SelectCategory}/>
                <Auth exact path={'/add-listing/:slug/:type_id'} component={AddListing}/>
                <Auth exact path={'/edit-listing/:id'} component={EditListing}/>
                <Auth exact path={'/dashboard'} component={Dashboard}/>
                <Auth path={'/dashboard/my-contact'} component={MyContact}/>
                <Auth path={'/dashboard/my-listing'} component={MyListing}/>
                <Auth path={'/dashboard/pay-now/:id'} component={PayNow}/>
                <Auth path={'/dashboard/change-password'} component={ChangePassword}/>
                <Footer/>
                <ScrollToTop smooth/>
            </BrowserRouter>
        </>
    );
}

export default withNamespaces()(App);
