import React, {useEffect, useState} from 'react';
import {withNamespaces} from "react-i18next";
import axios from "axios";
import Constants from "../../Constants";
import {useHistory} from "react-router-dom";

const CityArea = ({t}) => {
    const history = useHistory()
    const [cities , setCities] = useState([])

    const getCities = () => {
        axios.get(Constants.BASE_URL + '/get-cities').then(res => {
            setCities(res.data.data)
        })
    }

    const handleRedirect = (id) => {
        history.push(`/explore?city_id=${id}`)
    }

    useEffect(()=>{
        getCities()
    }, [])

    return (
        <div className="container city-area section-gap">
            <div className="row">
                <div className="col-md-12">
                    <p>{t('Regions')}</p>
                    <h4>{t('Explore by country')}</h4>
                </div>
            </div>
            <div className="row section-gap">
                {cities.map((city, index)=>(
                    <div key={index} className="col-md-6 mb-4" onClick={()=>handleRedirect(city.id)}>
                        <div className="card my-card">
                            <div className="card-header my-card-header">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h1>{city.name}</h1>
                                    <h6>{city.listing}</h6>
                                </div>
                            </div>
                            <div className="my-card-body">
                                <img src={city.photo} alt={city.name} className={'card-img-top'}/>
                            </div>
                        </div>
                    </div>
                ))}


            </div>
        </div>
    );
};

export default withNamespaces() (CityArea);
