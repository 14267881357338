import React, {useEffect, useState} from 'react';
import video from "../../Assets/Image/cover.mp4";
import banner from "../../Assets/Image/banner.jpg";
import {withNamespaces} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import Constants from "../../Constants";

const Banner = ({t}) => {
    const history = useHistory()
    const [categories, setCategories] = useState([])
    const [input, setInput] = useState({
        search : '',
        category_id: ''
    })

    const getCategories = () => {
        axios.get(Constants.BASE_URL + '/get-categories').then(res => {
            setCategories(res.data.data)
            localStorage.categories= JSON.stringify(res.data.data)
        })
    }

    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }

    const search = () => {
        console.log(input)
        history.push(`/explore?category_id=${input.category_id}&search=${input.search}`)
    }
    useEffect(() => {
        getCategories()
    }, []);


    return (
        <div className="banner">
            <video playsInline autoPlay muted loop poster={banner} id="bgvid">
                <source src={video} type="video/mp4"/>
            </video>
            <div className="overly"/>
            <div className="content">
                <h1>{t('Discover great places in the world')}</h1>
                <p className="lead">{t("Let's uncover the best places to eat, drink, and shop nearest to you.")}</p>
                <div className="search-area">
                    <div className="row align-items-center">
                        <div className="col-5">
                            <div className="form-floating">
                                <input
                                    name={'search'}
                                    onChange={handleInput}
                                    value={input.search}
                                    type="text"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                />
                                <label htmlFor="floatingInput">{t('What are you looking for?')}</label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-floating">
                                <select
                                    name={'category_id'}
                                    onChange={handleInput}
                                    value={input.category_id}
                                    className="form-control text-muted"
                                    id="floatingInput2"
                                    placeholder="All categories"
                                >
                                    <option>Select Category</option>
                                    {categories.map((category, index) => (
                                        <option key={index} value={category.id}>{category.name}</option>
                                    ))}
                                </select>
                                <label htmlFor="floatingInput2">{t('All categories')}</label>
                            </div>
                        </div>
                        <div className="col-3">
                            <button onClick={search} className={'search-button'}><i className="fa-solid fa-magnifying-glass"></i> {t('Search')}</button>
                        </div>
                    </div>
                </div>
                <div className="icon-area">
                    <div className="row">
                        {categories.map((category, index)=>(
                            <div className="col pe-1" key={index}>
                                <Link to={`/explore?category_id=${category.id}`}>
                                    <div className="icon-item">
                                        <span dangerouslySetInnerHTML={{__html: category.icon}}/>
                                        <p>{category.name}</p>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withNamespaces()(Banner);
