import React from 'react';
import Slider from "react-slick";
import img1 from "../../Assets/Image/clients/1.png";
import img2 from "../../Assets/Image/clients/2.png";
import img3 from "../../Assets/Image/clients/3.png";
import img4 from "../../Assets/Image/clients/4.png";
import img5 from "../../Assets/Image/clients/5.png";
import img6 from "../../Assets/Image/clients/6.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ClientArea = () => {
    const settings = {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        centerPadding: '15px',
    };
    return (
        <div className="container clients-area">
            <div className="row">
                <div className="col-md-12">
                    <Slider {...settings}>
                        <div>
                            <img src={img1} alt={'Client Logo'} />
                        </div>
                        <div>
                            <img src={img2} alt={'Client Logo'} />
                        </div>
                        <div>
                            <img src={img3} alt={'Client Logo'} />
                        </div>
                        <div>
                            <img src={img4} alt={'Client Logo'} />
                        </div>
                        <div>
                            <img src={img5} alt={'Client Logo'} />
                        </div>
                        <div>
                            <img src={img6} alt={'Client Logo'} />
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default ClientArea;
