import React from 'react';
import Banner from "../Includes/Banner";
import CategoryArea from "../Includes/CategoryArea";
import FeatureArea from "../Includes/FeatureArea";
import LatestListing from "../Includes/LatestListing";
import Parallax from "../Includes/Parallax";
import CityArea from "../Includes/CityArea";
import Testimonial from "../Includes/Testimonial";
import ClientArea from "../Includes/ClientArea";
import {Helmet} from "react-helmet";
import {withNamespaces} from "react-i18next";

const Home = ({t}) => {
    return (
        <>
            <Helmet>
                <title> {t('Welcome')} | {t('Mega Multi-Service IO')}</title>
            </Helmet>
            <Banner/>
            <CategoryArea/>
            <FeatureArea title={t('Featured places')} sub_title={t('Listings')}/>
            <LatestListing  title={t('Explore places')} sub_title={t('Latest')}/>
            <Parallax/>
            <CityArea/>
            <Testimonial/>
            <ClientArea/>
        </>
    );
};

export default withNamespaces() (Home);
