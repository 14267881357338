import React, {useState} from 'react';
import {withNamespaces} from "react-i18next";
import {Helmet} from "react-helmet";
import axios from "axios";
import Constants from "../../Constants";
import Swal from "sweetalert2";
import $ from "jquery";

const ContactUs = ({t}) => {

    const [input, setInput] = useState({})
    const [errors, setErrors] = useState([])

    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }

    const sendMessage = () => {
        axios.post(Constants.BASE_URL + '/contact-us-store', input).then(res => {
            if (res.status == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.msg,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setInput({})
                window.location.reload()
            }
        }).catch(errors => {
            if (errors.response.status == 422) {
                setErrors(errors.response.data.errors)
            }
        })
    }

    return (
        <div>
            <Helmet>
                <title>{t('Contact Us')} | {t('Mega Multi-Service IO')}</title>
            </Helmet>
            <div className="container feature-area section-gap-top">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <h4>Contact Us</h4>
                    </div>
                </div>
                <div className="row section-gap justify-content-center align-items-center">
                    <div className="col-md-6">
                        <p className="text-start">Address: </p>
                        <address className="ms-3">8444 Reseda Blvd, Northridge, CA 91324, United States</address>
                        <p className="text-start">Phone: </p>
                        <address className="ms-3"><a className="text-dark" href="tel:(818) 773 – 7571">(818) 773 – 7571</a></address>
                    </div>
                    <div className="col-md-6">
                        <p className="lead text-start text-about">If you have any query feel free to contact us.</p>
                        <label className="w-100">
                            Name
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Name"
                                name={'name'}
                                onChange={handleInput}
                                value={input.name}
                            />
                            {errors.name != undefined ?
                                <small
                                    className="text-danger validation-error-message">{errors.name[0]}</small> : null
                            }
                        </label>
                        <label className="w-100 mt-3">
                            Email
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Enter Your Email"
                                name={'email'}
                                onChange={handleInput}
                                value={input.email}
                            />
                            {errors.email != undefined ?
                                <small
                                    className="text-danger validation-error-message">{errors.email[0]}</small> : null
                            }
                        </label>
                        <label className="w-100 mt-3">
                            Subject
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Subject"
                                name={'subject'}
                                onChange={handleInput}
                                value={input.subject}
                            />
                            {errors.subject != undefined ?
                                <small
                                    className="text-danger validation-error-message">{errors.subject[0]}</small> : null
                            }
                        </label>
                        <label className="w-100 mt-3">
                            Message
                            <textarea
                                className="form-control"
                                placeholder="Enter Message"
                                rows={8}
                                name={'message'}
                                onChange={handleInput}
                                value={input.message}
                            />
                            {errors.message != undefined ?
                                <small
                                    className="text-danger validation-error-message">{errors.message[0]}</small> : null
                            }
                        </label>
                        <button  onClick={sendMessage} className="mt-3 btn btn-success">Send Message</button>
                    </div>
                </div>
            </div>
            <div className="container-fluid g-0">
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe className={'google-map'} src="https://www.google.com/maps/d/embed?mid=1jeDH2NgJ-imvD1W3cINH3eWOgJArOye2&ehbc=2E312F"  frameBorder="0" allowFullScreen={true} aria-hidden="false" tabIndex="0" height="400"/>
            </div>
        </div>
    );
};

export default withNamespaces() (ContactUs);
