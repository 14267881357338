import React, {useEffect, useState} from 'react';
import cover from "./../../Assets/Image/category_1.jpeg"
import {Helmet} from "react-helmet";
import axios from "axios";
import Constants from "../../Constants";
import {useParams} from "react-router-dom";
import Swal from "sweetalert2";
import $ from 'jquery'

const ListingDetails = () => {

    const params = useParams()
    const [listing, setListing] = useState([])
    const [input, setInput] = useState({})
    const [errors, setErrors] = useState([])
    const [bigImage, setBigImage] = useState('')

    const getListing = () => {
        axios.get(Constants.BASE_URL + '/listing/' + params.slug).then(res => {
            setListing(res.data.data)
        })
    }

    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
        setInput(prevState => ({...prevState, 'listing_id': listing.id}))
    }

    const sendMessage = () => {
        axios.post(Constants.BASE_URL + '/contact', input).then(res => {
            if (res.status == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.msg,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setInput({})
                $('#contact_form').trigger("reset")
                window.location.reload()
            }
        }).catch(errors => {
            if (errors.response.status == 422) {
                setErrors(errors.response.data.errors)
            }
        })
    }

    useEffect(() => {
        getListing()
    }, []);


    return (
        <div className={"container-fluid p-0"}>
            <Helmet>
                <title>Listing Details | Mega Multi-Service IO</title>
            </Helmet>
            <div className="details-cover-photo">
                <div className="container cover-content">
                    <div className="row">
                        <div className="col-md-12 text-end">
                            <div className="cover-content-area">
                                {listing.information != undefined ?
                                    <>
                                        <a href={""}><i className="fa-solid fa-phone"></i> {listing.information.phone}
                                        </a>
                                        <a href={""}><i
                                            className="fa-solid fa-location-dot"></i> {listing.information.address}</a>
                                        <button><i className="fa-regular fa-comment"></i> Add review</button>
                                        <button><i className="fa-regular fa-heart"></i> Bookmark</button>
                                        <button><i className="fa-solid fa-arrow-up-right-from-square"></i></button>
                                    </> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <img src={listing.feature_photo} alt={"cover photo"}/>
            </div>
            <div className="container details-page-header">
                <div className="row align-items-center">
                    <div className="col-md-7">
                        <div className="row">
                            <div className="col-3">
                                <div className="details-page-header-area">
                                    <img src={listing.feature_photo} alt={"logo"}
                                         className={"details-cover img-thumbnail"}/>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <h5>{listing.title}</h5>
                                <p>{listing.sub_title}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        {/*<ul className={"details-page-nav"}>*/}
                        {/*    <li><a href={"/"}>Profile</a></li>*/}
                        {/*    <li><a href={"/"}>Reviews</a></li>*/}
                        {/*    <li><a href={"/"}>Events</a></li>*/}
                        {/*    <li><a href={"/"}>Store</a></li>*/}
                        {/*</ul>*/}
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-header">
                                <h4>Description</h4>
                            </div>
                            <div className="card-body">
                                {listing.description}
                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-header">
                                <h4>Contact business</h4>
                            </div>
                            <div className="card-body">
                                <form id="contact_form">
                                    <label className="w-100">
                                        Your Name (required)
                                        <input
                                            name={'name'}
                                            onChange={handleInput}
                                            value={input.name}
                                            type={"text"}
                                            className={'form-control form-control-sm mt-2'}
                                        />
                                        {errors.name != undefined ?
                                            <small
                                                className="text-danger validation-error-message">{errors.name[0]}</small> : null
                                        }
                                    </label>
                                    <label className="w-100 mt-3">
                                        Your Email (required)
                                        <input
                                            name={'email'}
                                            onChange={handleInput}
                                            value={input.email}
                                            type={"email"}
                                            className={'form-control form-control-sm mt-2'}
                                        />
                                        {errors.email != undefined ?
                                            <small
                                                className="text-danger validation-error-message">{errors.email[0]}</small> : null
                                        }
                                    </label>
                                    <label className="w-100 mt-3">
                                        Your Phone (required)
                                        <input
                                            name={'phone'}
                                            onChange={handleInput}
                                            value={input.phone}
                                            type={"text"}
                                            className={'form-control form-control-sm mt-2'}
                                        />
                                        {errors.phone != undefined ?
                                            <small
                                                className="text-danger validation-error-message">{errors.phone[0]}</small> : null
                                        }
                                    </label>
                                    <label className="w-100 mt-3">
                                        Subject (required)
                                        <input
                                            name={'subject'}
                                            onChange={handleInput}
                                            value={input.subject}
                                            type={"text"}
                                            className={'form-control form-control-sm mt-2'}
                                        />
                                        {errors.subject != undefined ?
                                            <small
                                                className="text-danger validation-error-message">{errors.subject[0]}</small> : null
                                        }
                                    </label>
                                    <label className="w-100 mt-3">
                                        Your Message (required)
                                        <textarea
                                            name={'message'}
                                            onChange={handleInput}
                                            value={input.message}
                                            className={'form-control form-control-sm mt-2'}
                                        />
                                        {errors.message != undefined ?
                                            <small
                                                className="text-danger validation-error-message">{errors.message[0]}</small> : null
                                        }
                                    </label>
                                    <button type="button" onClick={sendMessage} className={"request-button btn"}>SEND
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-body">
                                <div className="listing-social py-5">
                                    <h4 className={'mb-4'}>Social Links</h4>
                                    {listing?.information?.facebook_url != undefined ?
                                        <a href={listing.information.facebook_url} target={'_blank'}><i
                                            className="fa-brands fa-facebook"></i></a> : null
                                    }
                                    {listing?.information?.twitter_url != undefined ?
                                        <a href={listing.information.twitter_url} target={'_blank'}><i
                                            className="fa-brands fa-twitter"></i></a> : null
                                    }
                                    {listing?.information?.instagram_url != undefined ?
                                        <a href={listing.information.instagram_url} target={'_blank'}><i
                                            className="fa-brands fa-instagram"></i></a> : null
                                    }
                                    {listing?.information?.wage_url != undefined ?
                                        <a href={listing.information.wage_url} target={'_blank'}><i
                                            className="fa-solid fa-link"></i></a> : null
                                    }
                                    {listing?.information?.whatssap_url != undefined ?
                                        <a href={''} target={'_blank'}><i
                                            className="fa-brands fa-whatsapp"></i></a> : null
                                    }
                                    {listing?.information?.website_url != undefined ?
                                        <a href={listing.information.website_url} target={'_blank'}><i
                                            className="fa-solid fa-earth-americas"></i></a> : null
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-header">
                                <p className={"mb-0"}><i className="fa-regular fa-clock"></i> Opening Hours</p>
                                <hr/>
                                <ul className={'list-group'}>
                                    {listing.time != undefined ? listing.time.map((time, index) => (
                                        <li key={index} className={'list-group-item'}>
                                            <div className="d-flex justify-content-between">
                                                <div className="w-50">{time.day}</div>
                                                <div className="w-50">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            {time.open != 'Closed' ?
                                                                <i className="fa-solid fa-door-open me-1 text-success"></i> :
                                                                <i className="fa-solid fa-door-closed me-1 text-danger"></i>
                                                            }
                                                            {time.open}
                                                        </div>
                                                        <div>
                                                            {time.close != null ?
                                                                <>
                                                                    <i className="fa-solid fa-door-closed me-1 text-danger"></i> {time.close}
                                                                </> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )) : null}
                                </ul>
                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-header">
                                <h4>Gallery</h4>
                            </div>
                            <div className="card-body">
                                {listing.photos != undefined ? listing.photos.map((photo, index) => (
                                    <img
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                        key={index}
                                        onClick={()=>setBigImage(photo.photo)}
                                        src={photo.photo}
                                        className={"img-thumbnail details-photo-item cursor-pointer"}
                                         alt={"gallery"}/>
                                )) : null}

                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-header">
                                <h4>Location</h4>
                            </div>
                            <div className="card-body">
                                {listing.information != undefined ?
                                    <iframe
                                        style={{width: "100%"}}
                                        height="250"
                                        src={listing.information.map_url}
                                        allowFullScreen="" loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"></iframe> : null
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header p-2">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Image Preview</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <img src={bigImage} className={'img-thumbnail'} alt={'big image'} />
                        </div>
                    </div>
                </div>
            </div>



        </div>
    );
};

export default ListingDetails;
