import React, {useEffect, useState} from 'react';
import category_1 from "../../Assets/Image/service_photo/1.JPG";
import category_2 from "../../Assets/Image/service_photo/2.JPG";
import category_3 from "../../Assets/Image/service_photo/3.JPG";
import {withNamespaces} from "react-i18next";
import {Link} from "react-router-dom";

const CategoryArea = ({t}) => {

    const [categories, setCategories] = useState([])

    useEffect(() => {
        if (localStorage.categories == undefined) {
            setTimeout(() => {
                setCategories(JSON.parse(localStorage.categories))
            }, 1500)
        }else{
            setCategories(JSON.parse(localStorage.categories))
        }

    }, [])
    return (
        <div className="container categories section-gap">
            <div className="row">
                <div className="col-md-12">
                    <p>{t('Categories')}</p>
                    <h4>{t('What do you need to find?')}</h4>
                </div>
            </div>
            <div className="row my-5 justify-content-center">
                {categories.map((category, index) => (
                    <div className="col-md-4 mb-4" key={index}>
                        <Link to={`/explore?category_id=${category.id}`}>
                            <div className="category-item">
                                <img src={category.photo} alt={category.name}/>
                                <div className="overly"/>
                                <div className="content">
                                    <span dangerouslySetInnerHTML={{__html:category.icon}}/>
                                    <h4>{t(category.name)}</h4>
                                    <h6>{category.listing} {t(`listings`)}</h6>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default withNamespaces()(CategoryArea);
