import React from 'react';
import {withNamespaces} from "react-i18next";
import LatestListing from "../Includes/LatestListing";

const Featured = ({t}) => {
    return (
        <div>
            <div className="row">
                <div className="col-md-12 mt-5">
                    <p className="section-p">{t('Latest')}</p>
                    <h1 className="section-headline">{t('Explore places')}</h1>
                </div>
            </div>
            <LatestListing  title={t('Explore places')} sub_title={t('Latest')}/>
        </div>
    );
};

export default withNamespaces()(Featured) ;
