import React from 'react';
import {withNamespaces} from "react-i18next";
import blogImg1 from './../../Assets/Image/service_photo/1.JPG'
import blogImg2 from './../../Assets/Image/service_photo/2.JPG'
import blogImg3 from './../../Assets/Image/service_photo/3.JPG'
import {Link} from "react-router-dom";

const Blog = ({t}) => {

    const blogs = [
        {
            img: blogImg1,
            link: '/residential-trash-removal',
            date: '24 February 2020',
            title: 'Yosemite – Bass Lake Vacation Rental House',
            text: '“Study nature, love nature, stay close to nature. It will never fail you'
        },
        {
            img: blogImg2,
            link: '/yard-clean-up',
            date: '24 February 2020',
            title: 'Yosemite – Bass Lake Vacation Rental House',
            text: '“Study nature, love nature, stay close to nature. It will never fail you'
        },
        {
            img: blogImg3,
            link: '/general-clean-up',
            date: '24 February 2020',
            title: 'Yosemite – Bass Lake Vacation Rental House',
            text: '“Study nature, love nature, stay close to nature. It will never fail you'
        },


    ]


    return (
        <div className="container section-gap">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="section-headline">{t('Blog')}</h1>
                </div>
            </div>
            <div className="row">
                {blogs.map((blog, index) => (
                    <div className="col-md-4 my-5">
                        <div className="home-blog">
                            <img src={blog.img} className="img-thumbnail" alt="blog"/>
                            <p className="my-2"><small>{blog.date}</small></p>
                            <Link to="#">
                                <h4>{blog.title}</h4>
                            </Link>
                            <p>{blog.text}
                                <Link to="#">
                                    <button className="btn btn-sm btn-outline-success ms-2">Read More</button>
                                </Link>
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="col-md-12 mt-5 text-center">
                <Link to={'#'}>
                    <button className={'explore-button'}>{t('Explore More Blogs')}</button>
                </Link>
            </div>
        </div>
    );
};

export default withNamespaces()(Blog);
