import React, {useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {Helmet} from "react-helmet";
import {withNamespaces} from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import Constants from "../../../Constants";

const Register = ({t}) => {

    const history = useHistory()
    const [input, setInput] = useState({})
    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }
    const [errors, setErrors] = useState([])
    const register = () => {
        axios.post(Constants.BASE_URL + '/register', input).then(res => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                padding: '10px',
                title: res.data.msg,
                toast: true,
                showConfirmButton: false,
                timer: 5000
            })

            localStorage.user_token = res.data.token
            localStorage.user_name = res.data.name
            localStorage.user_photo = res.data.photo
            localStorage.user_gender = res.data.gender
            setErrors([])
            history.push('/dashboard')
            window.location.reload()

        }).catch(errors => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                toast: true,
                padding: '10px',
                background: 'red',
                iconColor: 'white',
                color: 'white',
                title: 'Ops! Something going wrong',
                showConfirmButton: false,
                timer: 5000
            })
            if (errors.response.status == 422) {
                setErrors(errors.response.data.errors)
            }
        })
    }

    return (
        <>
            <Helmet>
                <title>{t('Register')} | {t('Mega Multi-Service IO')}</title>
            </Helmet>
            <div className={'nav-gap'}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="card shadow">
                                <div className="card-header">
                                    <h4>{t('Register')}</h4>
                                </div>
                                <div className="card-body text-center">
                                    <label className={'w-75 text-start'}>
                                        {t('Full Name')}
                                        <input
                                            value={input.name}
                                            onChange={handleInput}
                                            name={'name'}
                                            type={'text'}
                                            className={'form-control'}
                                            placeholder={t('Enter your full name')}
                                        />
                                        <p className={'login-error-msg'}><small>{errors.name !== undefined ? errors.name[0] : null}</small></p>
                                    </label>
                                    <label className={'w-75 mt-4 text-start'}>
                                        {t('Email')}
                                        <input
                                            value={input.email}
                                            onChange={handleInput}
                                            name={'email'}
                                            type={'email'}
                                            className={'form-control'}
                                            placeholder={t('Enter your email')}
                                        />
                                        <p className={'login-error-msg'}><small>{errors.email !== undefined ? errors.email[0] : null}</small></p>
                                    </label>
                                    <label className={'w-75 mt-4 text-start'}>
                                        {t('Phone')}
                                        <input
                                            value={input.phone}
                                            onChange={handleInput}
                                            name={'phone'}
                                            type={'phone'}
                                            className={'form-control'}
                                            placeholder={t('Enter your phone')}
                                        />
                                        <p className={'login-error-msg'}><small>{errors.phone !== undefined ? errors.phone[0] : null}</small></p>
                                    </label>
                                    <label className={'w-75 mt-4 text-start'}>
                                        {t('Password')}
                                        <input
                                            value={input.password}
                                            onChange={handleInput}
                                            name={'password'}
                                            type={'password'}
                                            className={'form-control'}
                                            placeholder={t('Enter your password')}
                                        />
                                        <p className={'login-error-msg'}><small>{errors.password !== undefined ? errors.password[0] : null}</small></p>
                                    </label>
                                    <label className={'w-75 mt-4 text-start'}>
                                        {t('Re-enter Password')}
                                        <input
                                            value={input.password_confirmation}
                                            onChange={handleInput}
                                            name={'password_confirmation'}
                                            type={'password'}
                                            className={'form-control'}
                                            placeholder={t('Retype your password')}
                                        />
                                    </label>
                                    <div className="d-grid mt-4" style={{placeItems:'center'}}>
                                        <button  onClick={register} type={'button'} className={'btn  w-75 btn-outline-info'}>{t('Register')}</button>
                                    </div>
                                    <p className={"mt-3"}> {t('Already registered?')} <Link to={"/login"}>{t('Login here')}</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default withNamespaces()(Register);
