import React from 'react';
import {withNamespaces} from "react-i18next";
import Testimonial from "../Includes/Testimonial";

const TestimonialPage = ({t}) => {
    return (
        <div>
            <div className="row mt-5">
                <div className="col-md-12">
                    <h1 className="section-headline">{t('Testimonial')}</h1>
                </div>
            </div>
            <Testimonial/>
        </div>
    );
};

export default withNamespaces() (TestimonialPage);
