import React, {useEffect, useState} from 'react';
import axios from "axios";
import Constants from "../../../Constants";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {withNamespaces} from "react-i18next";
import Nav from "./includes/Nav";
import Loader from "../../Includes/Loader";

const MyContact = ({t}) => {

    const [contacts, setContacts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const getMyContact = () => {
        setIsLoading(true)
        axios.get(Constants.BASE_URL + '/get-contact').then(res => {
            setContacts(res.data.data)
            setIsLoading(false)
        })
    }

    useEffect(()=>{
        getMyContact()
    }, [])

    return (
        <>
            <Helmet>
                <title>{t('My Contacts')} | {t('Mega Multi-Service IO')}</title>
            </Helmet>
            <div className={'container my-5'}>
                <div className="row">
                    <div className="col-md-12">
                        <Nav/>
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>My Contact</h4>
                            </div>
                            <div className="card-body">
                                {isLoading ?
                                    <Loader/>:
                                    <table className="table small-table table-hover table-bordered">
                                        <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Sender Details</th>
                                            <th>Subject</th>
                                            <th>Message</th>
                                            <th>Listing Title</th>
                                            <th>Contacted At</th>
                                            {/*<th>Action</th>*/}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {contacts.map((contact, index)=>(
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>
                                                    <p className="mb-0 d-flex align-items-center"><i className="fa-solid fa-user me-1"/> {contact.name}</p>
                                                    <p className="mb-0 text-success d-flex align-items-center"><i className="fa-solid fa-envelope me-1"/> {contact.email}</p>
                                                    <p className="mb-0 d-flex align-items-center"><i className="fa-solid fa-phone me-1"/> {contact.phone}</p>
                                                </td>
                                                <td>{contact.subject}</td>
                                                <td>{contact.message}</td>
                                                <td>{contact.listing_title}</td>
                                                <td>{contact.created_at}</td>
                                                {/*<td>Action</td>*/}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default withNamespaces() (MyContact);
