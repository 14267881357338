import React, {useEffect, useState} from 'react';
import {withNamespaces} from 'react-i18next';
import axios from "axios";
import Constants from "../../Constants";
import {Link, useParams} from "react-router-dom";

const SelectCategory = ({t}) => {
    const params = useParams()
    const [services, set_services] = useState([])
    const getService = () => {
        axios.get(Constants.BASE_URL + '/get-service-category').then(res => {
            set_services(res.data.data)
        })
    }
    useEffect(()=>{
        getService()
    }, [])

    return (
        <div>
            <div className="container section-gap">
                <div className="row">
                    <div className="col-md-12">
                        <p className="section-p">{t('Pricing')}</p>
                        <h1 className="section-headline">{t('Choose a Package')}</h1>
                    </div>
                </div>
                <div className="row mt-5">
                    {services.map((service, index)=>(
                        <div className="col-md-4" key={index}>
                            <div className="card my-card">
                                <div className="card-header my-card-header">
                                    <h4>{service.title}</h4>
                                </div>
                                <div className="card-body">
                                    <h1>${service.price}</h1>
                                    <ul>
                                        <li>{t('One listing submission')}</li>
                                        <li>{service.validate} {t(' days expiration')}</li>
                                        <li>{t('Submit your business')}</li>
                                        <li>{t('Create events')}</li>
                                        <li>{t('Rent real estate')}</li>
                                    </ul>
                                    <div className="d-grid">
                                       <Link className={'w-100'} to={'/add-listing/'+service.slug+'/'+params.type_id}><button className={'w-100'}>{t('Buy Package')} <i className="fa-solid fa-arrow-right ms-4"></i></button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}


                </div>
            </div>
        </div>
    );
};

export default withNamespaces()(SelectCategory);
