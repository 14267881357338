import React from 'react';
import loader_img from './../../Assets/Image/loader/Spinner-1s-200px.svg'
const Loader = (props) => {
    return (
        <div className={'loader-container'}>
            <div className="image-container">
                <img src={loader_img} alt={'Loader'}/>
                {props.progress != undefined ?
                    <h4>{props.progress}%</h4> :null
                }
            </div>
        </div>
    );
};

export default Loader;
