import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {Helmet} from "react-helmet";
import {withNamespaces} from "react-i18next";
import axios from "axios";
import Constants from "../../../Constants";

const Login = ({t}) => {


    const history = useHistory()
    const [input, setInput] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [err, setErr] = useState([])

    const handleInput = (e) => setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))

    const handleLogin = () => {
        setIsLoading(true)
        axios.post(Constants.BASE_URL + '/login', input).then(res => {
            setIsLoading(false)

            localStorage.user_email = res.data.email
            localStorage.user_name = res.data.name
            localStorage.user_photo = res.data.photo
            localStorage.user_token = res.data.token
            window.location.reload()
        }).catch(errors => {
            if (errors.response.status === 422) {
                setErrors(errors.response.data.errors)
                setErr(errors.message)
                setIsLoading(false)
            }
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (localStorage.user_token !== undefined) {
            history.push('/dashboard')
        }
    }, []);


    return (
        <>
            <Helmet>
                <title>{t('Login')} | {t('Mega Multi-Service IO')}</title>
            </Helmet>
            <div className={'nav-gap'}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="card shadow">
                                <div className="card-header">
                                    <h4>{t('Login')}</h4>
                                </div>
                                <div className="card-body text-center">
                                    <label className={'w-75 text-start mt-4 position-relative'}>
                                        Email/Phone
                                        <input
                                            type={'text'}
                                            className={'form-control'}
                                            name={'email'}
                                            value={input.email}
                                            onChange={handleInput}
                                        />
                                        <p className={'login-error-msg'}><small>{errors.email != undefined ? errors.email[0] : null}</small></p>
                                        <p className={'login-error-msg'}><small>{err.message}</small></p>
                                    </label>
                                    <label className={'w-75 text-start mt-4 position-relative'}>
                                        password
                                        <input
                                            type={'password'}
                                            className={'form-control'}
                                            name={'password'}
                                            value={input.password}
                                            onChange={handleInput}
                                        />
                                        <p className={'login-error-msg'}><small>{errors.password != undefined ? errors.password[0] : null}</small></p>
                                    </label>
                                    <div className="d-grid  mt-4" style={{placeItems: 'center'}}>
                                        <button disabled={isLoading} onClick={handleLogin} dangerouslySetInnerHTML={{
                                            __html: isLoading ?
                                                `<div class="spinner-border spinner-border-sm" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                                </div>` : 'Login'
                                        }} className={'w-75 btn-sm btn btn-info'}/>
                                    </div>
                                    <p className={"mt-3 cursor-pointer"}><Link to={"/forgot-password"}>{t('Forget your password?')}</Link> </p>
                                    <p className={"mt-3"}>{t('Not yet registered?')}<Link to={"/register"}> {t('Register here')}</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default withNamespaces()(Login);
