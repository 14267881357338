import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import Constants from "../../../Constants";
import {Helmet} from "react-helmet";
import {withNamespaces} from "react-i18next";
import Nav from "./includes/Nav";
import Swal from "sweetalert2";
import Loader from "../../Includes/Loader";

const MyListing = ({t}) => {
    const query = new URLSearchParams(document.location.search)
    const [listings, setListings] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const getMyContact = () => {
        setIsLoading(true)
        axios.get(Constants.BASE_URL + '/my-listing').then(res => {
            setListings(res.data.data)
            setIsLoading(false)
        })
    }

    const handleListingDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Listing Will be deleted and also associated all data will be deleted",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${Constants.BASE_URL}/listing/${id}`).then(res => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        toast: true,
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    getMyContact()
                })
            }
        })
    }

    useEffect(()=>{
        getMyContact()
        if (query.get('status') == 1){
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Payment Successful',
                toast: true,
                showConfirmButton: false,
                timer: 1500
            })
        }else if(query.get('status') == 0){
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Payment Canceled',
                toast: true,
                showConfirmButton: false,
                timer: 1500
            })
        }
    },[])

    return (
        <>
            <Helmet>
                <title>{t('My Listing')} | {t('Mega Multi-Service IO')}</title>
            </Helmet>
            <div className={'container my-5'}>
                <div className="row">
                    <div className="col-md-12">
                        <Nav/>
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>My Listing</h4>
                            </div>
                            <div className="card-body">
                                {isLoading?
                                    <Loader/>:
                                    <table className="table small-table table-hover table-bordered">
                                        <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Title/Sub Title</th>
                                            <th>Category/Status</th>
                                            <th>Address/Phone</th>
                                            <th>Photo</th>
                                            <th>Created At</th>
                                            <th>Pricing</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {listings.map((list, index)=>(
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>
                                                    <p className="mb-0">{list.title}</p>
                                                    <p className="text-success mb-0">{list.sub_title}</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">Category: {list.category}</p>
                                                    <p className="text-success mb-0">Status: {list.status}</p>
                                                    <p className="mb-0">Is Approved: {list.is_approved}</p>
                                                    <p className="text-success mb-0">Package: {list.package}</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">{list.address}</p>
                                                    <p className="text-success mb-0">{list.phone}</p>
                                                </td>
                                                <td>
                                                    <img src={list.feature_photo} alt={list.title} className="img-thumbnail" width={100} />
                                                </td>
                                                <td>{list.created_at}</td>
                                                <td>
                                                    <div style={{width: '100px'}}>
                                                        <p className="mb-0">Price: ${list.price}</p>
                                                        <p className="text-success mb-0">Paid: ${list.paid_amount}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    {
                                                        list.price > list.paid_amount &&
                                                        <Link to={'/dashboard/pay-now/'+list.id+'?amount='+list.price}><button className={'btn btn-success btn-sm'}>Pay Now</button></Link>
                                                    }
                                                    <Link to={`/edit-listing/${list.id}`}><button className={'btn btn-sm btn-warning'}><i className={'fas fa-edit'}/></button></Link>
                                                    <button onClick={() => handleListingDelete(list.id)}
                                                            className={'btn btn-sm btn-danger m-1'}><i
                                                        className={'fas fa-trash'}/></button>
                                                </td>
                                            </tr>
                                        ))}

                                        </tbody>
                                    </table>
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default withNamespaces() (MyListing);
