import React from 'react';
import {withNamespaces} from "react-i18next";

const Services = ({t}) => {
    return (
        <div className="container section-gap">
            <div className="row">
                <div className="col-md-12">
                    <p className="section-p">{t('Services')}</p>
                    <h1 className="section-headline">{t('Our Services')}</h1>
                </div>
                <div className="row mt-5">
                    <div className="col-md-4">
                        <div className="card my-card service-card">
                            <div className="card-body">
                                <i className="fa-solid fa-map-location fa-2x"/>
                                <h4 className={'py-3'}>Find spots</h4>
                                <p>Silent sir say desire fat him letter. Whatever settling goodness too and honoured she building answered her.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card my-card service-card">
                            <div className="card-body">
                                <i className="fa-solid fa-cart-flatbed-suitcase fa-2x"/>
                                <h4 className={'py-3'}>Buy tickets & Coupons</h4>
                                <p>Silent sir say desire fat him letter. Whatever settling goodness too and honoured she building answered her.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card my-card service-card">
                            <div className="card-body">
                                <i className="fa-solid fa-location-pin fa-2x"/>
                                <h4 className={'py-3'}>Add your own listing</h4>
                                <p>Silent sir say desire fat him letter. Whatever settling goodness too and honoured she building answered her.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-6">
                        <div className="card my-card service-card">
                            <div className="card-body">
                                <i className="fa-solid fa-location-crosshairs fa-3x"/>
                                <h4 className={'py-3'}>Find spots</h4>
                                <p>Silent sir say desire fat him letter. Whatever settling goodness too and honoured she building answered her.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card my-card service-card">
                            <div className="card-body">
                                <i className="fa-solid fa-cart-plus fa-3x"/>
                                <h4 className={'py-3'}>Buy tickets & Coupons</h4>
                                <p>Silent sir say desire fat him letter. Whatever settling goodness too and honoured she building answered her.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withNamespaces()(Services);
