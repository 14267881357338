import React, {useEffect, useRef, useState} from 'react';
import {Helmet} from "react-helmet";
import LatestListing from "../Includes/LatestListing";
import {withNamespaces} from "react-i18next";
import {Link, useLocation, useParams} from "react-router-dom";
import axios from "axios";
import Constants from "../../Constants";

const Explore = ({t}) => {
    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    const isFirstRender = useRef(true)

    const [categories, setCategories] = useState([])
    const [types, setTypes] = useState([])
    const [cities, setCities] = useState([])
    const [subCities, setSubCities] = useState([])
    const [isFilterClear, setIsFilterClear] = useState(1)

    const [input, setInput] = useState({
        category_id: '',
        type_id: '',
        city_id: '',
        search: '',
        sub_city_id: '',
    })
    const [listings, setListings] = useState([])

    const handleFilter = () => {
        setInput({
            category_id: '',
            type_id: '',
            city_id: '',
            sub_city_id: '',
            search: ''
        })
        setIsFilterClear(isFilterClear+1)
    }
    useEffect(()=>{
        if (isFirstRender.current) {
            isFirstRender.current = false;
        }else{
            getListing()
        }
    },[isFilterClear])
    const getListing = () => {
        setListings([])
        axios.get(Constants.BASE_URL + `/listing?category_id=${input.category_id}&type_id=${input.type_id}&city_id=${input.city_id}&search=${input.search}&sub_city_id=${input.sub_city_id}`).then(res => {
            setListings(res.data.data)
        })
    }

    const handleSearch = () => {
        getListing()
    }

    const handleInput = (e) =>{
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
        if (e.target.name == 'city_id'){
            setInput(prevState => ({...prevState, sub_city_id: ''}))
            if (e.target.value != ''){
                getSubCities(e.target.value)
            }else{
                setSubCities([])
            }
        }
    }

    const getCategories = () => {
        axios.get(Constants.BASE_URL + '/get-categories').then(res => {
            setCategories(res.data.data)
        })
    }
    const getTypes = () => {
        axios.get(Constants.BASE_URL + '/get-types').then(res => {
            setTypes(res.data.data)
        })
    }
    const getCities = () => {
        axios.get(Constants.BASE_URL + '/get-cities').then(res => {
            setCities(res.data.data)
        })
    }
    const getSubCities = (id) => {
        axios.get(Constants.BASE_URL + `/get-sub-cities/${id}`).then(res => {
            setSubCities(res.data.data)
        })
    }

    useEffect(() => {
        setInput(prevState => ({...prevState, city_id : searchParams.get('city_id') != null ? searchParams.get('city_id') : ''}))
        setInput(prevState => ({...prevState, search : searchParams.get('search') != null ? searchParams.get('search') : ''}))
        setInput(prevState => ({...prevState, category_id : searchParams.get('category_id') != null ? searchParams.get('category_id') : ''}))
        getCategories()
        getTypes()
        getCities()
       // getListing()
        setTimeout(()=>{
            document.getElementById('search').click()
        }, 1000)

    }, []);


    return (
        <div>
            <Helmet>
                <title>{t('Explore')} | {t('Mega Multi-Service IO')}</title>
            </Helmet>
            <div className="container section-gap-top">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className={"section-headline"}>{t('What are you looking for?')}</h1>
                        <p className={"text-pink mt-3"}>{t('Search or select categories')}</p>
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <div className="form-floating mb-3">
                                    <select
                                        className="form-select"
                                        name={'category_id'}
                                        id="category_id"
                                        placeholder="Select Category"
                                        onChange={handleInput}
                                        value={input.category_id}
                                    >
                                        <option value={''}>Select Category</option>
                                        {categories.map((category, index) => (
                                            <option key={index} value={category.id}>{category.name}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="category_id">Select Category</label>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-floating mb-3">
                                    <select
                                        className="form-select"
                                        name={'type_id'}
                                        id="type_id"
                                        placeholder="Select Type"
                                        onChange={handleInput}
                                        value={input.type_id}
                                    >
                                        <option value={''}>Select Type</option>
                                        {types.map((type, index) => (
                                            <option key={index} value={type.id}>{type.title}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="category_id">Select Type</label>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-floating mb-3">
                                    <select
                                        className="form-select"
                                        name={'city_id'}
                                        id="city_id"
                                        placeholder="Select County"
                                        onChange={handleInput}
                                        value={input.city_id}
                                    >
                                        <option value={''}>Select County</option>
                                        {cities.map((city, index) => (
                                            <option key={index} value={city.id}>{city.name}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="city_id">Select County</label>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-floating mb-3">
                                    <select
                                        className="form-select"
                                        name={'sub_city_id'}
                                        id="sub_city_id"
                                        placeholder="Select City"
                                        onChange={handleInput}
                                        value={input.sub_city_id}
                                        disabled={input.city_id  == '' || Object.keys(subCities).length < 1 ? true :false }
                                    >
                                        <option value={''}>Select City</option>
                                        {subCities.map((subCity, index) => (
                                            <option key={index} value={subCity.id}>{subCity.name}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="sub_city_id">Select City</label>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-2">
                                <div className="d-grid">
                                    <button id={'search'} onClick={handleSearch} className={'btn btn-lg theme-button'}>Search</button>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="d-grid">
                                    <button onClick={handleFilter} className={'btn btn-lg theme-button'}>Clear Filter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container feature-area">
                <div className="row mt-4">
                    {listings.map((listing, index) => (
                        <div key={index} className="col-md-4 mt-4">
                            <Link to={'/listing/' + listing.slug} key={index}>
                                <div className="feature-item">
                                    <img src={listing.feature_photo} alt={'Feature'} className={'feature-image'}/>
                                    <div className="overly"/>
                                    <div className="content">
                                        <div className="content-data">
                                            <div className="content-image">
                                                <img src={listing.feature_photo} alt={'Feature'}
                                                     className={'feature-image'}/>
                                            </div>
                                            <h4>{listing.title}</h4>
                                            <h6>{listing.sub_title}</h6>
                                            <a href={listing.phone}><i
                                                className="fa-solid fa-phone-volume"></i> {listing.phone}</a>
                                            <address><i className="fa-solid fa-location-pin"></i> {listing.address}
                                            </address>
                                        </div>
                                    </div>
                                    <div className="content-footer">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <button><i className="fa-brands fa-servicestack"></i></button>
                                                <span> {listing.category}</span>
                                                <button data-tip="hello world" className={'ms-3'}>1+</button>

                                            </div>
                                            <div>
                                                <button data-tip="hello world"><i
                                                    className="fa-solid fa-magnifying-glass"></i></button>
                                                <button><i className="fa-solid fa-heart"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="badge-area">
                                        <button><i className="fa-solid fa-bolt"></i></button>
                                        <button>{t('Open')}</button>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}

                    <div className="col-md-12 mt-5 text-center">
                        <Link to={'/listing'}>
                            <button className={'explore-button'}>{t('Explore More Places')}</button>
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    )
        ;
};

export default withNamespaces()(Explore);
