import React from 'react';
import {withNamespaces} from "react-i18next";
import {Link} from "react-router-dom";

const Parallax = ({t}) => {
    return (
        <div className="container-fluid parallax-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <div className="content">
                            <h1 className={'text-uppercase display-5'}>{t('Discover events')}</h1>
                            <p className={'lead'}>{t("Start exploring events and learn what's happening near you.")}</p>
                            <Link to={'/explore'} ><button>{t('Explore Now')}</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withNamespaces() (Parallax);
