import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import Constants from "../../../Constants";

const PayNow = () => {
    const params = useParams()
    const query = new URLSearchParams(document.location.search)
    const [amount, setAmount] = useState(query.get('amount'))

    const handleAmountInput = (e) => {
        setAmount(e.target.value)
    }
    console.log(window.location.host )
    const handlePay = () => {
       window.location.href = Constants.PAYMENT_URL+'/make-payment?listing_id='+params.id+'&amount='+amount
    }

    return (
        <div className={'container mt-5'}>
            <div className="row justify-content-center">
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-header">
                            <h4>Pay Now</h4>
                        </div>
                       <div className="card-body">
                           <div className="input-group">
                               <span className="input-group-text">$</span>
                               <input
                                   className={'form-control'}
                                   type={'number'}
                                   value={amount}
                                   onChange={handleAmountInput}
                               />
                           </div>
                           <div className="d-grid">
                               <button onClick={handlePay} className={'btn btn-success mt-2'}>Pay Now</button>
                           </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayNow;
