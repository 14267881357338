import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import es from './locale/es.json'
import en from './locale/en.json'

const resources = {
    en: {
        translation: en
    },
    es :{
        translation: es
    }
};

i18n
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.language != undefined ? localStorage.language  : "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
