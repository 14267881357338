import React, {useEffect, useState} from 'react';
import {Link, NavLink} from "react-router-dom";
import logo from "../../Assets/Image/MegaMultiServicio.png";
import { withNamespaces } from 'react-i18next';

const NavBar = ({ t }) => {
    const [language, setLanguage] =useState()

    const scrollEvent = (e) => {
        console.log(e.target.scrollTop)
    }

    const handleLanguage = (e) => {
        setLanguage(e.target.value)
        localStorage.language = e.target.value
        window.location.reload()
    }
    useEffect(()=>{
        setLanguage(localStorage.language != undefined ? localStorage.language : 'en')
    }, [])

    return (
        <nav className="navbar navbar-expand-lg custom-navbar position-relative" onScroll={scrollEvent}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-3 col-12">
                        <Link className="navbar-brand" to={"/"}>
                            <img src={logo} alt={'Logo'} className={'logo'}/>
                        </Link>
                    </div>
                    <div className="col-md-9 col-12">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav me-0 ms-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <NavLink className="nav-link text-white" to={"/"}>{t('Home')}</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink activeClassName="selected" className="nav-link" to={"/about-us"}>{t('About Us')}</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink activeClassName="selected" className="nav-link" to={"/explore"}>{t('Explore')}</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink activeClassName="selected" className="nav-link" to={"/listing"}>{t('Listing')}</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink activeClassName="selected" className="nav-link" to={"/contact-us"}>{t('Contact Us')}</NavLink>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {t('More')}
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li><Link className="dropdown-item" to={"/featured"}>{t('Featured Section')}</Link></li>
                                            <li><Link className="dropdown-item" to={"/blog"}>{t('Blog')}</Link></li>
                                            <li><Link className="dropdown-item" to={"/price-plan"}>{t('Pricing Plans')}</Link></li>
                                            <li><Link className="dropdown-item" to={"/testimonial"}>{t('Testimonials')}</Link></li>
                                            <li><Link className="dropdown-item" to={"/services"}>{t('Services')}</Link></li>
                                            <li><Link className="dropdown-item" to={"/privacy-policy"}>{t('Privacy Policy')}</Link></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item ms-3">
                                        {localStorage.user_token == undefined ?
                                            <NavLink activeClassName="selected"  className="nav-link" to={'/login'}><i className="fa-solid fa-user"></i> {t('Sign in')}</NavLink> :
                                            <NavLink activeClassName="selected"  className="nav-link" to={'/dashboard'}><i className="fa-solid fa-user"></i> {t('Dashboard')}</NavLink>
                                        }
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={'/'}><i className="fa-solid fa-cart-flatbed-suitcase"></i></Link>
                                    </li>
                                    <li className="nav-item ms-3">
                                        <Link className="nav-link add-listing-button" to={'/select-type'}><i className="fa-solid fa-plus"></i> {t('Add Listing')}</Link>
                                    </li>
                                </ul>
                            </div>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className={'language-select'}>
                                <select value={language} onChange={handleLanguage} className={'form-select form-select-sm'}>
                                    <option value={'en'}>EN</option>
                                    <option value={'es'}>ES</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default withNamespaces() (NavBar);
