const Constants = {
    // BASE_URL: 'http://127.0.0.1:8000/api/client',
     //   BASE_URL: 'https://www.api.megamultiservicio.com/api/client',
    //PAYMENT_URL: 'http://localhost:8000',
     BASE_URL : window.location.hostname === 'localhost' ? 'http://localhost:8000/api/client':  'https://www.api.megamultiservicio.com/api/client',
    PAYMENT_URL: window.location.hostname === 'localhost' ? 'http://localhost:8000' : 'https://www.api.megamultiservicio.com',
}
export default Constants




