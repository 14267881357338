import React from 'react';
import {Helmet} from "react-helmet";
import FeatureArea from "../Includes/FeatureArea";
import LatestListing from "../Includes/LatestListing";
import {withNamespaces} from "react-i18next";

const Listing = ({t}) => {
    return (
        <div>
            <Helmet>
                <title>{t('Listing')} | {t('Mega Multi-Service IO')}</title>
            </Helmet>
            <LatestListing title={"LISTING FEED"} sub_title={"VERSION ONE"}/>
            <FeatureArea title={"Listing carousel"} sub_title={"Section"}/>
        </div>
    );
};

export default withNamespaces() (Listing);
