import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import axios from "axios";
import Constants from "../../../Constants";

const ForgetPassword = () => {
    const history = useHistory()
    const [input, setInput] = useState({email: ''})
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [err, setErr] = useState('')

    const handleInput = (e) => setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))

    const handleResetPassword = () => {
        setIsLoading(true)
        axios.post(Constants.BASE_URL + '/forget-password', input).then(res => {
            setErrors([])
            setErr(res.data.message)
            setIsLoading(false)
        }).catch(errors => {
            if (errors.response.status === 422) {
                setErrors(errors.response.data.errors)
                setIsLoading(false)
            }
        })
    }

    return (
        <div className={'container'}>
            <div className="row justify-content-center">
                <div className="col-md-4">
                    <div className="card mt-5">
                        <div className="card-header">
                            <h4>Forget password</h4>
                        </div>
                        <div className="card-body">
                            <label className={'w-100 text-start position-relative'}>
                                Email/Phone
                                <input
                                    type={'text'}
                                    className={'form-control'}
                                    name={'email'}
                                    value={input.email}
                                    onChange={handleInput}
                                />
                                <p className={'login-error-msg'}><small>{errors.email !== undefined ? errors.email[0] : null}</small></p>
                                <p className={'login-error-msg'}><small>{err !=undefined ? err: null}</small></p>
                            </label>
                            <div className="d-grid mt-4">
                                <button disabled={isLoading} onClick={handleResetPassword} type={'button'} className={'btn btn-primary'}>
                                    {isLoading ?
                                        <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>: 'Reset Password'}

                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgetPassword;
