import React from 'react';
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import Constants from "../../../../Constants";
import Swal from "sweetalert2";

const Nav = () => {

    const history = useHistory()
    const handleLogOut = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You will be logout",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Logout'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(Constants.BASE_URL + '/logout').then(res => {

                    localStorage.removeItem('admin_token')
                    localStorage.removeItem('admin_name')
                    localStorage.removeItem('admin_email')

                    history.push('/')
                    window.location.reload()
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        toast: true,
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
            }
        })
    }
    
    return (
        <div>
            <div className="dashboard-menu">
                <ul>
                    <li><Link to="/dashboard">Dashboard</Link></li> |
                    <li><Link to="/dashboard/my-contact">My Contacts</Link></li> |
                    <li><Link to="/dashboard/my-listing">My Listing</Link></li> |
                    <li><Link to="/dashboard/change-password">Change Password</Link></li> |
                    <li><button onClick={handleLogOut}>Log out</button></li>
                </ul>
            </div>
        </div>
    );
};

export default Nav;
