import React from 'react';
import {withNamespaces} from "react-i18next";

const PrivacyPolicy = ({t}) => {
    return (
        <div>
            <div className="container section-gap">
                <div className="row">
                    <div className="col-md-12">
                        <p className="section-p">{t('OUR')}</p>
                        <h1 className="section-headline">{t('Privacy Policy')}</h1>
                    </div>
                </div>
                <div className="row mt-5 justify-content-center">
                    <div className="col-md-10">
                        <div className="card">
                            <div className="card-body">
                                <p>This policy details how data about you is collected/used/disclosed when you visit our websites and services or otherwise interact with us. If we update it, we will revise the date, place notices on</p>
                                <p>MegabusinessUSA.com/MegabusinessUSA.com/MegaNegociosUSA.com/MegaNegociosUS.com if a change is material, and/or obtain your consent as required by law.</p>

                                <h4>1. Protecting your privacy</h4>
                                <ul>
                                    <li><i className="fa-solid fa-check-double"></i> We take precautions to prevent unauthorized access to or misuse of data about you.</li>
                                    <li>We do not run ads, other than classified ads posted by our users.</li>
                                    <li>We do not share your data with third parties for marketing purposes.</li>
                                    <li>We do not engage in cross-marketing or link-referral programs with other sites.</li>
                                    <li>We do not employ tracking devices for marketing purposes.</li>
                                    <li>We do not send you unsolicited communications for marketing purposes.</li>
                                    <li>We do not engage in affiliate marketing (and prohibit it on MegabusinessUSA.com/MegabusinessUSA.com/MegaNegociosUSA.com/MegaNegociosUS.com).</li>
                                    <li>We do provide email proxy & relay to reduce unwanted mail.</li>
                                    <li>MegabusinessUSA.com/MegabusinessUSA.com/MegaNegociosUSA.com/MegaNegociosUS.com links to third party sites – please review their privacy policies.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withNamespaces() (PrivacyPolicy);
