import React, {useEffect} from "react";
import {Redirect, Route} from "react-router-dom";
import axios from "axios";

const Auth = ({component: Component, ...rest}) => {


    useEffect(() => {
        const token = localStorage.user_token
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

    }, [])

    const token = localStorage.user_token
    return (
        <Route
            {...rest}
            render={props =>
                token != undefined ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: props.location}
                        }}
                    />
                )
            }
        />
    )
}
export default Auth
