import React from 'react';
import img1 from "../../Assets/Image/testimonial/1.jpeg";
import img2 from "../../Assets/Image/testimonial/2.jpg";
import img3 from "../../Assets/Image/testimonial/4.jpeg";
import $ from "jquery";
import {withNamespaces} from "react-i18next";

const Testimonial = ({t}) => {

    const handleTestimonial = (id, own) => {
        $(id).slideDown().siblings().slideUp()
        $(own).addClass('active').siblings().removeClass('active')
    }

    return (
        <div className="container testimonial section-gap">
            <div className="row">
                <div className="col-md-12">
                    <div className="image-area">
                        <img id={'img_1'} className={'active'} onClick={()=>handleTestimonial('#content_1', '#img_1')}  src={img1} alt={'Testimonial'}/>
                        <img id={'img_2'} onClick={()=>handleTestimonial('#content_2', '#img_2')} src={img2} alt={'Testimonial'}/>
                        <img id={'img_3'} onClick={()=>handleTestimonial('#content_3', '#img_3')} src={img3} alt={'Testimonial'}/>
                    </div>
                    <div className="content-area">
                        <div className="content" id={'content_1'}>
                            <p className={'lead'}>{t('I am item content. Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.')}</p>
                            <h5>{t('CEO')}</h5>
                            <p className={'name'}>- MegaBusiness</p>
                        </div>
                        <div className="content" id={'content_2'} style={{display: 'none'}}>
                            <p className={'lead'}>{t('I am item content. Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.')}</p>
                            <h5>{t('Vice President')}</h5>
                            <p className={'name'}>- MegaBusiness</p>
                        </div>
                        <div className="content" id={'content_3'} style={{display: 'none'}}>
                            <p className={'lead'}> {t('I am item content. Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.')}</p>
                            <h5>{t('President')}</h5>
                            <p className={'name'}>- MegaBusiness</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withNamespaces() (Testimonial);
