import React from 'react';
import {Helmet} from "react-helmet";
import {withNamespaces} from "react-i18next";

const AboutUs = ({t}) => {
    return (
        <div>
            <Helmet>
                <title>{t('About Us')} | {t('Mega Multi-Service IO')}</title>
            </Helmet>

            <div className="container feature-area section-gap">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <h4>About Us</h4>
                    </div>
                    <div className="col-md-8 section-gap">
                        <p className="lead text-justify text-about">
                            As any business nowadays knows, online visibility is a must in trying to engage your audience and attract your customers. An optimized, product-data-rich website is an obvious perfect step, but once you’ve got that in place it’s time to look elsewhere. An easy next perfect step is to look for free business listings across the web.
                        </p>
                        <p className="lead text-justify mt-5 text-about">To help you out, we’ve put together a list of online business directories to list your business and increase your exposure.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withNamespaces() (AboutUs);
