import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {withNamespaces} from "react-i18next";
import axios from "axios";
import Constants from "../../Constants";

const SelectListingType = ({t}) => {
    const [types, setTypes] = useState([])
    const getTypes = () => {
        axios.get(Constants.BASE_URL + '/get-types').then(res => {
            setTypes(res.data.data)
        })
    }
    useEffect(() => {
        getTypes()
    }, []);


    return (
        <div className="container section-gap">
            <div className="row">
                <div className="col-md-12">
                    <p className="section-p">{t('Listing Type')}</p>
                    <h1 className="section-headline">{t('Choose a Listing Type')}</h1>
                </div>
            </div>
            <div className="row mt-5">
                {types.map((type, index)=>(
                    <div className="col-md-4" key={index}>
                        <Link className={'link-type'} to={`/select-category/${type.id}`}>
                            <div className="card my-card place-card">
                                <div className="card-body">
                                    <i className={`fa-solid fa-${type.fa_icon} fa-2x`}/>
                                    <h4>{type.title}</h4>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}

            </div>
        </div>
    );
};

export default withNamespaces()(SelectListingType);
