import React, {Suspense, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import Constants from "../../../Constants";
import Nav from "./includes/Nav";
import {Helmet} from "react-helmet";
import {withNamespaces} from "react-i18next";
import { CSSTransition } from 'react-transition-group'

const Dashboard = ({t}) => {
    const [data, setData] = useState([])

    const getMyContact = () => {
        axios.get(Constants.BASE_URL + '/get-dashboard').then(res => {
            setData(res.data)
        })
    }

    useEffect(()=>{
        getMyContact()
    }, [])

    return (
        <>
            <Helmet>
                <title>{t('Dashboard')} | {t('Mega Multi-Service IO')}</title>
            </Helmet>
            <div className={'container my-5'}>
                <div className="row">
                    <div className="col-md-12">
                        <Nav/>
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>Dashboard</h4>
                            </div>
                            <div className="card-body">
                                <div className="row justify-content-center">
                                    <div className="col-md-3">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4>Total Contacts</h4>
                                            </div>
                                            <div className="card-body text-center">
                                                {data.contact != undefined ?
                                                    <Link to="/dashboard/my-contact"><h1>{data.contact}</h1></Link>:
                                                    <span className="spinner-border spinner-border"/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4>Total Listing</h4>
                                            </div>
                                            <div className="card-body text-center">
                                                {data.listing != undefined ?
                                                    <Link to="/dashboard/my-listing"><h1 className={'animation in'}>{data.listing}</h1></Link>:
                                                    <span className="spinner-border spinner-border animation in"/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default withNamespaces() (Dashboard);
