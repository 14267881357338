import React, {useEffect, useState} from 'react';
import feature_i from "../../Assets/Image/feature.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {withNamespaces} from "react-i18next";
import {Link} from "react-router-dom";
import axios from "axios";
import Constants from "../../Constants";

const FeatureArea = ({t}, props ) => {

    const [listings, setListings] = useState([])
    const getListing = () => {
        axios.get(Constants.BASE_URL + '/listing').then(res => {
            setListings(res.data.data)
        })
    }

    useEffect(() => {
        getListing()
    }, []);



    const settings = {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplay: true,
        centerPadding: '15px',
    };

    return (
        <div className="container feature-area section-gap">
            <div className="row">
                <div className="col-md-12">
                    <p>{props.sub_title}</p>
                    <h4>{props.title}</h4>
                </div>
            </div>
            <div className="row my-5">
                <Slider {...settings}>
                    {listings.map((listing, index)=>(
                        <div key={index} className="col-md-4 mt-4">
                            <Link to={'/listing/'+listing.slug} key={index}>
                                <div className="feature-item">
                                    <img src={listing.feature_photo} alt={'Feature'} className={'feature-image'}/>
                                    <div className="overly"/>
                                    <div className="content">
                                        <div className="content-data">
                                            <div className="content-image">
                                                <img src={listing.feature_photo} alt={'Feature'} className={'feature-image'}/>
                                            </div>
                                            <h4>{listing.title}</h4>
                                            <h6>{listing.sub_title}</h6>
                                            <a href={listing.phone}><i className="fa-solid fa-phone-volume"></i> {listing.phone}</a>
                                            <address><i className="fa-solid fa-location-pin"></i> {listing.address}</address>
                                        </div>
                                    </div>
                                    <div className="content-footer">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <button><i className="fa-brands fa-servicestack"></i></button>
                                                <span> {listing.category}</span>
                                                <button data-tip="hello world" className={'ms-3'}>1+</button>

                                            </div>
                                            <div>
                                                <button data-tip="hello world"><i className="fa-solid fa-magnifying-glass"></i></button>
                                                <button><i className="fa-solid fa-heart"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="badge-area">
                                        <button><i className="fa-solid fa-bolt"></i></button>
                                        <button>{t('Open')}</button>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}

                </Slider>
            </div>
        </div>
    );
};

export default withNamespaces() (FeatureArea);
