import React from 'react';

const Footer = () => {
    return (
        <div className="container footer">
            <hr/>
            <p className={'py-4'}><small>© megamultiservicio.com</small></p>
        </div>
    );
};

export default Footer;
